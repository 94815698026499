// extracted by mini-css-extract-plugin
export var quizLP__footer = "LandingPage-module--quizLP__footer--NBd2z";
export var quizLP__middleSec__secRow = "LandingPage-module--quizLP__middleSec__secRow--fClnq";
export var quizLp__bannerCol = "LandingPage-module--quizLp__bannerCol--TLImF";
export var quizLp__bannerCol__btn = "LandingPage-module--quizLp__bannerCol__btn--IwRp2";
export var quizLp__bannerCol__hdng = "LandingPage-module--quizLp__bannerCol__hdng--eA8EE";
export var quizLp__bannerImgCol = "LandingPage-module--quizLp__bannerImgCol--Q1+ti";
export var quizLp__bannerImgCol__mob = "LandingPage-module--quizLp__bannerImgCol__mob--27WkW";
export var quizLp__bannerRow = "LandingPage-module--quizLp__bannerRow--6D6CP";
export var quizLp__cont = "LandingPage-module--quizLp__cont--kk6Ty";
export var quizLp__endRow = "LandingPage-module--quizLp__endRow--HHJhw";
export var quizLp__endRow__cardCol = "LandingPage-module--quizLp__endRow__cardCol--jKFzn";
export var quizLp__endRow__colContnt = "LandingPage-module--quizLp__endRow__colContnt--4Gggj";
export var quizLp__endRow__cta = "LandingPage-module--quizLp__endRow__cta--bvNs6";
export var quizLp__endRow__hdng = "LandingPage-module--quizLp__endRow__hdng--EaSB6";
export var quizLp__endRow__icnImg = "LandingPage-module--quizLp__endRow__icnImg--+QVEt";
export var quizLp__endRow__step = "LandingPage-module--quizLp__endRow__step--wQU5K";
export var quizLp__middleSec = "LandingPage-module--quizLp__middleSec--rVacm";
export var quizLp__middleSec__colCntn = "LandingPage-module--quizLp__middleSec__colCntn--HUkHI";
export var quizLp__middleSec__colImg = "LandingPage-module--quizLp__middleSec__colImg--DTEdZ";
export var quizLp__middleSec__hdng = "LandingPage-module--quizLp__middleSec__hdng--Svrvy";
export var quizLp__middleSec__row = "LandingPage-module--quizLp__middleSec__row--28Osp";