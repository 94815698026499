// extracted by mini-css-extract-plugin
export var conHeight = "QuizQuestion-module--conHeight--JhYSc";
export var conWithDxHeight = "QuizQuestion-module--conWithDxHeight--mhfm-";
export var heading_text = "QuizQuestion-module--heading_text--BKTlC";
export var logoConHeight = "QuizQuestion-module--logoConHeight--2LkPp";
export var logoImg = "QuizQuestion-module--logoImg--Ns8yO";
export var option = "QuizQuestion-module--option--WloBH";
export var option_selected = "QuizQuestion-module--option_selected--eHZm4";
export var question = "QuizQuestion-module--question--nv-zl";
export var quizQues__ctaDisabled = "QuizQuestion-module--quizQues__ctaDisabled--9pwHR";
export var quizQues__ctaEnabled = "QuizQuestion-module--quizQues__ctaEnabled--FQlKu";
export var quizQues__heightCta = "QuizQuestion-module--quizQues__heightCta--NWaGt";
export var quizQues__heightPara = "QuizQuestion-module--quizQues__heightPara--jV6P2";
export var quizQues__weightCol = "QuizQuestion-module--quizQues__weightCol--CmMfF";
export var quiz_heightDropDwn = "QuizQuestion-module--quiz_heightDropDwn--AAP4c";