import React from "react";

// // lib css
// import "bootstrap-steps/dist/bootstrap-steps.min.css";

import * as styles from "./Assessment.module.css";

const Steps = props => {
  const { progress, handleBackStep, goFirstStep } = props;

  return (
    <div className="row align-items-baseline">
      <div className="col-md-12">
        <div className={`row ${styles.actionContainer}`}>
          <div className="col-6 order-1 order-md-1 col-md-4  d-flex justify-content-start justify-md-content-center col-md-3 col-lg-3">
            <span
              role="presentation"
              onClick={handleBackStep}
              className={`icon-back smallIcon cursorPointer`}
            />
          </div>
          <div className="col-12 order-3 pt-4 pt-md-0 order-md-2 col-md-6 align-self-center col-lg-6">
            <div className={`progress ${styles.step__progressBar}`}>
              <div
                className={`progress-bar ${styles.progressHighBar}`}
                style={{ width: `${progress}%` }}
                role="progressbar"
                aria-valuenow="0"
                aria-label="progress"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div className="col-6 order-2 order-md-3 d-none justify-content-end justify-md-content-center col-md-3 col-lg-3">
            <span
              role="presentation"
              onClick={() => goFirstStep(0)}
              className="icon-cross smallIcon cursorPointer"
            ></span>
          </div>
        </div>
      </div>
      <div className="col-md-2"></div>
    </div>
  );

  // return (
  //   <ul className="steps">
  //     {steps.map((_, i) => {
  //       let stepClass = "";
  //       if (i <= currentStep) {
  //         stepClass = "step-active";
  //       }
  //       return (
  //         <li className={`step ${stepClass}`} key={i + 1}>
  //           <div className="step-content">
  //             <span className="step-circle">{i + 1}</span>
  //           </div>
  //         </li>
  //       );
  //     })}
  //   </ul>
  // );
};

export default Steps;
