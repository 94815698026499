import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// clevertap
import { sendToCT } from "../../../services/Clevertap";

// component
import FloatingCta from "../FloatingCta/FloatingCta";

//css
import * as styles from "./LandingPage.module.css";

const LandingPage = props => {
  const handleCtaClick = () => {
    // Clevertap event
    sendToCT(props.ctEvent, {
      action: "on clicking start free assessment cta landing page",
    });

    props.handleNextStep(1);
  };

  return (
    <div className="bgWhiteColor">
      {/* Floating Cta For Mobile */}
      {<FloatingCta ctaText="Start FREE Assessment" handleNextStep={props.handleNextStep} ctEvent={props.ctEvent} />}

      <div className={`container ${styles.quizLp__cont}`}>
        {/* 1st Section */}
        <div className={`row ${styles.quizLp__bannerRow}`}>
          <div
            className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 ${styles.quizLp__bannerImgCol__mob}`}
          >
            <StaticImage
              src="../../../images/quiz/quizv2_lp_banner.png"
              alt="Banner"
              placeholder="blurred"
              quality={90}
            />
          </div>
          <div
            className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 ${styles.quizLp__bannerCol}`}
          >
            <div className={styles.quizLp__bannerCol__hdng}>
              <h3>Don’t let diabetes lead to further health complications</h3>
              <p>
                Take the free test to assess your risk of developing more
                Diabetes health issues & how Twin Health can help to get over
                diabetes
              </p>
            </div>
            <div className={styles.quizLp__bannerCol__btn}>
              <button onClick={handleCtaClick}>Start FREE Assessment</button>
            </div>
          </div>
          <div
            className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 ${styles.quizLp__bannerImgCol}`}
          >
            <StaticImage
              src="../../../images/quiz/quizv2_lp_banner.png"
              alt="Banner"
              placeholder="blurred"
              quality={90}
            />
          </div>
        </div>
        {/* 2nd Section */}
        <div className={styles.quizLp__middleSec}>
          <div className={styles.quizLp__middleSec__hdng}>
            <h3>Diabetes is a Gateway to Serious Health Complications</h3>
          </div>
          <div className={`row ${styles.quizLp__middleSec__row}`}>
            <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12`}>
              <div className={styles.quizLp__middleSec__colImg}>
                <StaticImage
                  src="../../../images/quiz/quizv2_lp_icn1.png"
                  alt="Banner"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              <div className={styles.quizLp__middleSec__colCntn}>
                <h3>Cardiovascular Issues</h3>
                <p>
                  Increases the risk of heart disease, stroke, and high blood
                  pressure.
                </p>
              </div>
            </div>
            <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12`}>
              <div className={styles.quizLp__middleSec__colImg}>
                <StaticImage
                  src="../../../images/quiz/quizv2_lp_icn2.png"
                  alt="Banner"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              <div className={styles.quizLp__middleSec__colCntn}>
                <h3>Eye Problems (Retinopathy)</h3>
                <p>
                  Lead to vision problems and, if not managed, can result in
                  blindness.
                </p>
              </div>
            </div>
          </div>
          <div className={`row ${styles.quizLP__middleSec__secRow}`}>
            <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12`}>
              <div className={styles.quizLp__middleSec__colImg}>
                <StaticImage
                  src="../../../images/quiz/quizv2_lp_icn3.png"
                  alt="Banner"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              <div className={styles.quizLp__middleSec__colCntn}>
                <h3>Nerve Damage (Neuropathy)</h3>
                <p>
                  Causes damage to nerves, leading to symptoms such as numbness,
                  tingling, and pain, particularly in the extremities.
                </p>
              </div>
            </div>
            <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12`}>
              <div className={styles.quizLp__middleSec__colImg}>
                <StaticImage
                  src="../../../images/quiz/quizv2_lp_icn4.png"
                  alt="Banner"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
              <div className={styles.quizLp__middleSec__colCntn}>
                <h3>Kidney Disease (Nephropathy)</h3>
                <p>
                  Prolonged high blood sugar levels can damage the kidneys and
                  impair their function.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 3rd Section */}
        <div className={`row ${styles.quizLp__endRow}`}>
          <div className={`col-12 ${styles.quizLp__endRow__colContnt}`}>
            <h3>Prevent Health Complications by Reversing Diabetes</h3>
            <p>
              Yes, it’s possible to Reverse Diabetes. With our revolutionary
              technology - Whole Body Digital Twin it’s possible to live a
              Diabetes-free life with Twin Health
            </p>
          </div>
          <div className={`col-12 ${styles.quizLp__endRow__cardCol}`}>
            <div className={`row`}>
              <div className={`col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                <div className={styles.quizLp__endRow__icnImg}>
                  <StaticImage
                    src="../../../images/quiz/quizv2_lp_icn5.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                </div>
                <div className={styles.quizLp__endRow__step}>
                  <p>Step 1</p>
                </div>
                <div className={styles.quizLp__endRow__hdng}>
                  <h3>Take the Assessment Test</h3>
                  <p>Take the test and assess your current health conditions</p>
                </div>
              </div>
              <div className={`col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                <div className={styles.quizLp__endRow__icnImg}>
                  <StaticImage
                    src="../../../images/quiz/quizv2_lp_icn6.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                </div>
                <div className={styles.quizLp__endRow__step}>
                  <p>Step 2</p>
                </div>
                <div className={styles.quizLp__endRow__hdng}>
                  <h3>Book A Free Consultation</h3>
                  <p>
                    Learn how Twin Health can help you prevent further health
                    complications & help you reverse diabetes
                  </p>
                </div>
              </div>
              <div className={`col-lg-4 col-md-4 col-sm-12 col-xs-12`}>
                <div className={styles.quizLp__endRow__icnImg}>
                  <StaticImage
                    src="../../../images/quiz/quizv2_lp_icn7.png"
                    alt="Banner"
                    placeholder="blurred"
                    quality={90}
                  />
                </div>
                <div className={styles.quizLp__endRow__step}>
                  <p>Step 3</p>
                </div>
                <div className={styles.quizLp__endRow__hdng}>
                  <h3>Join our program</h3>
                  <p>Start your journey to a diabetes-free life</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`col-12 ${styles.quizLp__endRow__cta}`}>
            <button onClick={handleCtaClick}>Start FREE Assessment</button>
          </div>
        </div>
        {/* 4th Section */}
        <div className={`row`}>
          <div className={`col-12 ${styles.quizLP__footer}`}>
            <p>
              Disclaimer: Please note that the test is intended for
              informational purposes only and is not intended to be a substitute
              for medical advice. It is important to consult with a qualified
              healthcare provider to determine if you have any chronic metabolic
              condition.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
