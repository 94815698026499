import React, { Component } from "react";
import queryString from "query-string";

// seo
import Seo from "../../components/Seo";

// constants
import constants from "../../constants";

// local storage
import LocalStorageService from "../../utils/LocalStorageService";

// utils
import { saveUTM, splitName } from "../../utils";

// hooks
import { useQuizQuery } from "../../hooks/useQuizQuery";

// components
import HeaderWithLogo from "../../components/Header/HeaderWithLogo";
import LandingPage from "../../components/MetabolismAssessment/LandingPage/LandingPage";
import QuizQuestion from "../../components/MetabolismAssessment/QuizQuestion/QuizQuestion";
import QuizForm from "../../components/MetabolismAssessment/QuizForm/QuizForm";

class DiabetesQuizLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      userQuizResult: {},
      finalQuizScore: 0,
      userDetail: {},
      firstNameUser: "",
      lastNameUser: "",
      bmiScore: 0,
      loading: false,
    };
  }

  componentDidMount() {
    // Save UTM Params to Local Storage
    if (this.props.location.search) {
      saveUTM(this.props.location.search);
    }
  }

  // Move user to Next Screen
  handleNextStep = nextStep => {
    this.setState({ step: nextStep });
  };

  // to calculate the final score of the quiz
  handleCalculateQuizScore = async values => {
    if (values) {
      this.setState({ userQuizResult: values });
      LocalStorageService.setValue("userQuizResult", values);

      // calculate BMI score of the user
      // Height is in cm. so converting height into meter
      const heightInMeter = values.height / 100;
      // BMI formulae = weight (kg) / [height (m)]2
      const bmiScore = values.weight / (heightInMeter * heightInMeter);
      this.setState({ bmiScore: Math.round(bmiScore) });

      let bmiScoreRangeValue;
      if (bmiScore >= 18 && bmiScore < 23) {
        bmiScoreRangeValue = 11;
      } else if (bmiScore >= 23 && bmiScore < 25) {
        bmiScoreRangeValue = 9;
      } else if (bmiScore >= 25 && bmiScore < 30) {
        bmiScoreRangeValue = 7;
      } else if (bmiScore >= 30 && bmiScore < 35) {
        bmiScoreRangeValue = 4;
      } else {
        bmiScoreRangeValue = 2;
      }

      const calculateFinalScore = Number(
        values.drink +
          values.medication +
          values.medicationType +
          values.smoke +
          values.sugarLevel +
          bmiScoreRangeValue
      );

      this.setState({ finalQuizScore: calculateFinalScore });

      // Other potential health risk function
      const potentailRiskData = this.handlePotentialRisk(
        values.sugarLevel,
        bmiScore,
        values.smoke,
        values.drink
      );

      // Set risk status as high/moderate/low risk based on user quiz score
      if (calculateFinalScore > 90) {
        LocalStorageService.setValue("riskState", "Low Risk");
      } else if (calculateFinalScore > 70 && calculateFinalScore <= 90) {
        LocalStorageService.setValue("riskState", "Moderate Risk");
      } else {
        LocalStorageService.setValue("riskState", "High Risk");
      }

      LocalStorageService.setValue("finalQuizScore", calculateFinalScore);
      LocalStorageService.setValue("bmiScore", this.state.bmiScore);
      LocalStorageService.setValue("potentialRisk", potentailRiskData);
      
      // move to form screen
      this.handleNextStep(2);
    }
  };

  // capture user details from the form
  handleUserDetails = value => {
    // util function -> to split the name
    const { firstName, lastName } = splitName(value.name);

    this.setState({
      userDetail: value,
      firstNameUser: firstName,
      lastNameUser: lastName,
    });

    LocalStorageService.setValue("userName", firstName);
    LocalStorageService.setValue("userEmail", value.email);
  };

  // To Capture Other Potential Risk Based on HBA1C, BMI, Smoking And Drinking
  handlePotentialRisk = (hba1c, bmi, smoke, drink) => {
    let riskData = {
      eye: "moderate risk",
      kidney: "moderate risk",
      heartAttack: "moderate risk",
      amputation: "no risk",
      diabetesDeath: "moderate risk",
      hypertension: "moderate risk",
      fattyLiver: "no risk",
      liver: "no risk",
      cancer: "moderate risk",
      lung: "moderate risk",
      stroke: "no risk",
    };

    const updateRiskData = updates => {
      riskData = { ...riskData, ...updates };
    };

    if (hba1c <= 13) {
      if (bmi >= 30) {
        if (smoke < 15 && drink === 15) {
          // Smoking | No Drinking
          updateRiskData({ amputation: "high risk" });
        } else if (smoke === 15 && drink < 15) {
          // No Smoking | Drinking
          updateRiskData({
            amputation: "high risk",
            lung: "no risk",
            cancer: "high risk",
            fattyLiver: "high risk",
            stroke: "moderate risk",
          });
        } else if (smoke === 15 && drink === 15) {
          // No Smoking | No Drinking
          updateRiskData({
            amputation: "high risk",
            lung: "no risk",
            cancer: "no risk",
          });
        }
      } else {
        if (smoke < 15 && drink < 15) {
          // Smoking | Drinking
          updateRiskData({
            amputation: "high risk",
            hypertension: "no risk",
            fattyLiver: "high risk",
            liver: "moderate risk",
          });
        } else if (smoke < 15 && drink === 15) {
          // Smoking | No Drinking
          updateRiskData({
            amputation: "high risk",
            hypertension: "no risk",
          });
        } else if (smoke === 15 && drink < 15) {
          // No Smoking | Drinking
          updateRiskData({
            amputation: "high risk",
            fattyLiver: "high risk",
            cancer: "high risk",
            stroke: "moderate risk",
            lung: "no risk",
          });
        } else if (smoke === 15 && drink === 15) {
          // No Smoking | No Drinking
          updateRiskData({
            amputation: "high risk",
            hypertension: "no risk",
            lung: "no risk",
            cancer: "no risk",
          });
        }
      }
    } else {
      if (bmi < 30) {
        if (smoke < 15 && drink < 15) {
          // Smoking | Drinking
          updateRiskData({
            eye: "no risk",
            kidney: "no risk",
            diabetesDeath: "no risk",
            fattyLiver: "high risk",
            stroke: "moderate risk",
          });
        } else if (smoke < 15 && drink === 15) {
          // Smoking | No Drinking
          updateRiskData({
            eye: "no risk",
            kidney: "no risk",
            stroke: "moderate risk",
          });
        } else if (smoke === 15 && drink < 15) {
          //  No Smoking | Drinking
          updateRiskData({
            eye: "no risk",
            kidney: "no risk",
            diabetesDeath: "no risk",
            cancer: "high risk",
            stroke: "moderate risk",
            fattyLiver: "high risk",
            liver: "moderate risk",
            lung: "no risk",
          });
        } else if (smoke === 15 && drink === 15) {
          // No Smoking | No Drinking
          updateRiskData({
            eye: "no risk",
            kidney: "no risk",
            heartAttack: "no risk",
            diabetesDeath: "no risk",
            hypertension: "no risk",
            cancer: "no risk",
            lung: "no risk",
          });
        }
      } else {
        // BMI >= 30
        updateRiskData({
          eye: "no risk",
          kidney: "no risk",
          heartAttack: "no risk",
          diabetesDeath: "no risk",
          hypertension: "no risk",
          cancer: "no risk",
          lung: "no risk",
        });
      }
    }
    return riskData;
  };

  render() {
    const { step, finalQuizScore, userQuizResult, bmiScore } = this.state;

    const { utm_source } = queryString.parse(this.props.location.search);

    return (
      <div>
        <Seo
          title="Diabetes Risk Assessment Quiz | Twin"
          description="The quiz will help you in taking action against diabetes."
        />

        <HeaderWithLogo logoPosition="center" background="white" />

        {step === 0 && (
          <LandingPage
            handleNextStep={this.handleNextStep}
            ctEvent="metabolism_assessment_initiated"
          />
        )}

        {step === 1 && (
          <QuizQuestion
            handleNextStep={this.handleNextStep}
            handleCalculateQuizScore={this.handleCalculateQuizScore}
            quizQuestions={constants.METABOLIC_ASSESSMENT_QUESTIONS}
          />
        )}

        {step === 2 && (
          <QuizForm
            handleNextStep={this.handleNextStep}
            handleUserDetails={this.handleUserDetails}
            userQuizResult={userQuizResult}
            utm_source={utm_source}
            finalQuizScore={finalQuizScore}
            bmiScore={bmiScore}
            ctEvent="metabolism_assessment_formCta"
            resultPageUrl="/quiz/diabetes-result"
          />
        )}
      </div>
    );
  }
}

const WithContext = props => {
  const quizPayloadData = useQuizQuery("metabolismCalculator");

  const finalQuizPayload = {
    quiz_payload: quizPayloadData,
  };

  return (
    <DiabetesQuizLandingPage {...props} metabolismQuiz={finalQuizPayload} />
  );
};

export default WithContext;
