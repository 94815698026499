import React from "react";
import PropTypes from "prop-types";
import Multiselect from "multiselect-react-dropdown";

const propTypes = {
  displayValue: PropTypes.string,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.array,
  showCheckbox: PropTypes.bool,
  selectionLimit: PropTypes.number,
  placeholder: PropTypes.string,
  hidePlaceholder: PropTypes.bool,
  showArrow: PropTypes.bool,
  customCloseIcon: PropTypes.element,
  customArrow: PropTypes.element,
};

const defaultProps = {
  displayValue: "key",
  onSelect: () => {},
  onRemove: () => {},
  options: [
    {
      cat: "Group 1",
      key: "Option 1",
    },
    {
      cat: "Group 1",
      key: "Option 2",
    },
    {
      cat: "Group 1",
      key: "Option 3",
    },
  ],
  showCheckbox: true,
  selectionLimit: 2,
  placeholder: "Select",
  hidePlaceholder: true,
  showArrow: true,
  customCloseIcon: <i class="fa-solid fa-xmark"></i>,
  customArrow: <i class="fa-solid fa-xmark"></i>,
};

const DropDownQuiz = React.forwardRef((props, ref) => {
  return (
    <div>
      <Multiselect
        singleSelect={props.singleSelect}
        displayValue={props.displayValue}
        onSelect={item => props.onSelect(item)}
        onRemove={item => props.onRemove(item)}
        id="css_custom"
        options={props.options}
        showCheckbox={props.showCheckbox}
        selectionLimit={props.selectionLimit}
        placeholder={props.placeholder}
        hidePlaceholder={props.hidePlaceholder}
        className="multiSelect__dropDown__quiz"
        ref={ref}
        showArrow={props.showArrow}
        customCloseIcon={<>{props.customCloseIcon}</>}
        customArrow={props.customArrow}
        selectedValues={props.selectedValues}
        style={{
          chips: {
            padding: "3px 8px",
            background: "#FFF",
            marginRight: "7px",
            color: "#757575",
            border: "1px solid #757575",
            borderRadius: "13px",
          },
          multiselectContainer: {
            color: "#111111",
            background: "none",
            cursor: "pointer",
            height: "46px",
          },
          input: {
            marginTop: "0px",
          },
          searchBox: {
            border: "1px solid #F47920",
            borderRadius: "50px",
            padding: "0px 0px 0px 8px",
            height: "64px",
            background: "#FFF",
            cursor: "pointer",
          },
          option: {
            color: "#111111",
          },
          optionContainer: {
            background: "#FFFFFF",
          },
          displayBlock: {
            marginTop: "4px",
            height: "20px",
          },
          highlightOption: {
            background: "#FFF",
          },
          // inputField: {
          //   display: "none",
          // },
        }}
      />
    </div>
  );
});

DropDownQuiz.propTypes = propTypes;
DropDownQuiz.defaultProps = defaultProps;

export default DropDownQuiz;
