// extracted by mini-css-extract-plugin
export var floatCta__ctaLoader = "FloatingCta-module--floatCta__ctaLoader--rWolk";
export var floatCta__loader = "FloatingCta-module--floatCta__loader--uWT6Y";
export var floatCta_anchorLink = "FloatingCta-module--floatCta_anchorLink--8Omqc";
export var floatCta_bottomSec = "FloatingCta-module--floatCta_bottomSec--mUOE2";
export var floatCta_bottomSec_actPrce = "FloatingCta-module--floatCta_bottomSec_actPrce--MUKbV";
export var floatCta_bottomSec_actPrce_cpnDisable = "FloatingCta-module--floatCta_bottomSec_actPrce_cpnDisable--Edwd3";
export var floatCta_bottomSec_arrwIcn = "FloatingCta-module--floatCta_bottomSec_arrwIcn--Vv9yL";
export var floatCta_sec = "FloatingCta-module--floatCta_sec--F6A9M";
export var floatCta_topSec_icnSec = "FloatingCta-module--floatCta_topSec_icnSec--L4stw";
export var floatCta_topSec_icnSec_tckIcn = "FloatingCta-module--floatCta_topSec_icnSec_tckIcn--4mSe+";
export var floatCta_topSec_txtSec = "FloatingCta-module--floatCta_topSec_txtSec--KJ4lW";