import React, { useState } from "react";
import { navigate } from "gatsby";
import Loader from "react-loader-spinner";

// Sticky Cta
import StickyCtaHook from "./StickyCtaHook";

// clevertap
import { sendToCT } from "../../../services/Clevertap";

// styles
import * as styles from "./FloatingCta.module.css";

const FloatingCta = props => {
  const [loading, setLoading] = useState(false);

  const { isSticky } = StickyCtaHook();

  const handleCtaProperty = () => {
    if (props.calendlyUrl) {
      setLoading(true);

      // Simulating a delay before navigating to the Calendly URL
      setTimeout(() => {
        navigate(props.calendlyUrl);

        // Set a timeout to hide the loader after a reasonable time (e.g., 5 seconds)
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }, 1000);
    } else {
      props.handleNextStep(1);
    }

    // clevertap event
    sendToCT(props.ctEvent, {
      action: "on clicking start free assessment cta",
    });
  };

  return (
    <div className={styles.floatCta_sec}>
      {isSticky && (
        <div className={`container`}>
          <div className="row mt-3">
            <div className={`col-12 ${styles.floatCta_bottomSec}`}>
              <button onClick={handleCtaProperty}>
                <span className={loading && styles.floatCta__ctaLoader}>
                  {props.ctaText}
                </span>
                <span
                  className={`icon-arrow-forward ${styles.floatCta_bottomSec_arrwIcn}`}
                  aria-hidden="true"
                ></span>
                <span className={styles.floatCta__loader}>
                  <Loader
                    type="TailSpin"
                    color="#FFF"
                    height={30}
                    width={30}
                    radius={1}
                    visible={loading}
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloatingCta;
