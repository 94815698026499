import React, { Component } from "react";

// constants
import constants from "../../../constants";

//component
import DropDown from "../../DropDown/DropdownQuiz";

// Components
import Steps from "../Steps";

// css
import * as styles from "./QuizQuestion.module.css";

// images
import { StaticImage } from "gatsby-plugin-image";

class QuizQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      questions: this.props.quizQuestions,
      step: 0,
      progress: 0,
      selectedHeight: [],
      weightValue: 0,
      heightCtaEnabled: false,
      weightCtaEnabled: false
    };
  }

  handleHeightSelection = value => {
    if (value.length > 0) {
      this.setState({ selectedHeight: value, heightCtaEnabled: true });
    } else {
      this.setState({ heightCtaEnabled: false });
    }
  };

  handleWeightValue = e => {
    let inputValue = e.target.value;

    // Use a regular expression to check if the input contains only numbers and accepts only 3 digits
    if (!/^\d*$/.test(inputValue)) {
      // If the input contains non-numeric characters, clear the input
      e.target.value = "";
      this.setState({ weightCtaEnabled: false });
    } else if (inputValue.length > 3) {
      // If the input has more than 3 digits, limit it to the first 3 digits
      e.target.value = inputValue.slice(0, 3);
      const tempInputValue = e.target.value;
      inputValue = tempInputValue;
    }

    if (!inputValue) {
      this.setState({ weightCtaEnabled: false });
    } else if (!/^\d*$/.test(inputValue)) {
      this.setState({ weightCtaEnabled: false });
    } else {
      this.setState({ weightValue: inputValue, weightCtaEnabled: true });
    }
  };

  answerSelect = values => {
    const { questions, step } = this.state;
    const activeQuestion = questions[step];

    activeQuestion.options.map(option => {
      if (option.id === values.id) {
        option.selected = true;
      } else {
        option.selected = false;
      }
      return option;
    });

    this.setState({ activeQuestion });

    let nextStep = step;
    if (nextStep + 1 < questions.length) {
      nextStep = step + 1;
    } else {
      let result = {};
      for (let question of questions) {
        const found = question.options.find(item => item.selected === true);

        if (question.id === 1) {
          result.height = Number(this.state.selectedHeight[0].name);
        } else if (question.id === 2) {
          result.weight = Number(this.state.weightValue);
        } else if (question.id === 3) {
          result.sugarLevel = Number(found.score);
          result.sugarLevelOption = found.option;
        } else if (question.id === 4) {
          result.medicationType = Number(found.score);
          result.medicationTypeOption = found.option;
        } else if (question.id === 5) {
          result.medication = Number(found.score);
          result.medicationOption = found.option;
        } else if (question.id === 6) {
          result.smoke = Number(found.score);
          result.smokeOption = found.option;
        } else if (question.id === 7) {
          result.drink = Number(found.score);
          result.drinkOption = found.option;
        }
      }

      this.setState({
        progress: Math.round((this.state.step + 1 / questions.length) * 100),
      });

      setTimeout(() => {
        this.props.handleCalculateQuizScore(result);
      }, 300);

      return;
    }
    this.setState({
      progress: Math.round((nextStep / questions.length) * 100),
    });

    setTimeout(() => {
      this.setState({ step: nextStep });
    }, 400);
  };

  handleBackStep = () => {
    // Disable Weight Next Button
    if(this.state.step === 2) this.setState({ weightCtaEnabled: false })

    this.setState({
      progress: Math.round(
        ((this.state.step - 1) / this.state.questions.length) * 100
      ),
    });
    this.setState({ step: this.state.step - 1 });
  };

  render() {
    const { questions, step, selectedHeight, weightValue, heightCtaEnabled, weightCtaEnabled } =
      this.state;
    const activeQuestion = questions[step];

    return (
      <div>
        <div className="container twinContainer">
          <div
            className={`${
              this.props.appForDxStatus
                ? styles.conWithDxHeight
                : styles.conHeight
            }`}
          >
            <div className={`row`}>
              <div className="col-12">
                <div className="mt-3 mb-3 text-center">
                  <Steps
                    handleBackStep={
                      step === 0
                        ? () => this.props.handleNextStep(0)
                        : this.handleBackStep
                    }
                    progress={this.state.progress}
                    totalStep={questions.length}
                    currentStep={step}
                    goFirstStep={this.props.handleNextStep}
                  />
                </div>
              </div>
            </div>

            {questions[step]["type"] === "dropdown" ? (
              <div className={`row`}>
                <div className={`col-12 ${styles.quizQues__heightPara}`}>
                  <p>{questions[step].question}</p>
                </div>
                <div className={`col-12 ${styles.quiz_heightDropDwn}`}>
                  <DropDown
                    displayValue="display"
                    onSelect={this.handleHeightSelection}
                    onRemove={this.handleHeightSelection}
                    options={constants.QUIZ_HEIGHT}
                    showCheckbox={true}
                    selectionLimit={1}
                    placeholder="Select your height"
                    hidePlaceholder={true}
                    showArrow={true}
                    selectedValues={selectedHeight}
                    customCloseIcon={
                      <StaticImage
                        src="../../../images/bookPack/cross_2.png"
                        alt="cross_icon"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{
                          width: "14px",
                          height: "14px",
                          marginLeft: "4px",
                        }}
                      />
                    }
                    customArrow={
                      <StaticImage
                        src="../../../images/bookPack/arrow_dropdown.png"
                        alt="cross_icon"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{
                          marginTop: "9px",
                        }}
                      />
                    }
                  />
                </div>
                <div className={`col-12 ${styles.quizQues__heightCta}`}>
                  <button
                    className={
                      heightCtaEnabled
                        ? styles.quizQues__ctaEnabled
                        : styles.quizQues__ctaDisabled
                    }
                    disabled={!heightCtaEnabled}
                    onClick={() => this.answerSelect(selectedHeight[0])}
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <div>
                {/* To Show Weight UI */}
                {questions[step]["type"] === "inputtext" ? (
                  <div className={`row`}>
                    <div className={`col-12 ${styles.quizQues__heightPara}`}>
                      <p>
                        {questions[step].question}
                      </p>
                    </div>
                    <div className={`col-12 ${styles.quizQues__weightCol}`}>
                      <input
                        className={`form-control`}
                        placeholder="Please enter your weight in Kgs"
                        id="weight"
                        type="text"
                        maxLength={3}
                        inputMode="numeric"
                        onChange={this.handleWeightValue}
                      />
                    </div>
                    <div className={`col-12 ${styles.quizQues__heightCta}`}>
                      <button
                        className={
                          weightCtaEnabled
                            ? styles.quizQues__ctaEnabled
                            : styles.quizQues__ctaDisabled
                        }
                        disabled={!weightCtaEnabled}
                        onClick={() => this.answerSelect(weightValue)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : (
                  // To Show Rest Questions UI
                  <div className="row">
                    <div className="col-md-6 px-md-0 offset-md-3">
                      <div>
                        <div className={`mt-4 mb-5 ${styles.question}`}>
                          {activeQuestion["question"]}
                        </div>
                        {activeQuestion.options.map((option, index) => {
                          return (
                            <div
                              key={index}
                              className={`d-flex align-items-center ${
                                styles.option
                              } ${
                                option.selected ? styles.option_selected : ""
                              }`}
                              onClick={() => this.answerSelect(option)}
                              role="presentation"
                            >
                              <p> {option.option} </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default QuizQuestion;
