import { useStaticQuery, graphql } from "gatsby";

export const useQuizQuery = (component = "metabolismCalculator") => {
  const { allTwinPage } = useStaticQuery(
    graphql`
      query QUIZ_QUERY {
        allTwinPage(filter: { page: { eq: "quiz" } }) {
          edges {
            node {
              page
              component
              data {
                appointmentTypeID,
                hindiCalendarID,
                englishCalendarID,
                kannadaCalendarID,
                tamilCalendarID,
                teluguCalendarID,
                malayalamCalendarID
              }
            }
          }
        }
      }
    `
  );

  // console.log("all node", allTwinPage.edges);
  const findComponent = allTwinPage.edges.find(
    item => item.node.component === component
  );
  // console.log("findComponent", findComponent);

  let node = findComponent.node.data;

  return node;
};
