import { useLayoutEffect, useState, useCallback } from "react";

const StickyCta = (defaultSticky = true) => {
  const [isSticky, setIsSticky] = useState(defaultSticky);

  const toggleSticky = useCallback(
    ({ scrollY }) => {
        // console.log("scrollY", scrollY);
      if (scrollY >= 4000) {
        if (isSticky) {
          setIsSticky(false);
        }
      } else {
        if (!isSticky) {
          setIsSticky(true);
        }
      }
    },
    [isSticky]
  );

  useLayoutEffect(() => {
    const handleScroll = () => {
      toggleSticky(window);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [toggleSticky]);

  return { isSticky };
};

export default StickyCta;
