import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { StaticImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";
import Loader from "react-loader-spinner";

// services
import { sendToLSQ } from "../../../services/LeadSquared";
import { sendToCT } from "../../../services/Clevertap";

// constants
import constants from "../../../constants";

// component
import DropDown from "../../DropDown/DropDown";

// css
import * as styles from "./QuizForm.module.css";

const QuizForm = props => {
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [showLanguageError, setShowLanguageError] = useState("none");
  const [operatingSystem, setOperatingSystem] = useState(""); // To store operating system
  const [deviceType, setDeviceType] = useState(""); // To store the device type
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  // Find The Operating System Of The User
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    let operatingSystem = "";

    if (userAgent.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    } else if (userAgent.indexOf("Mac") !== -1) {
      operatingSystem = "Mac OS";
    } else if (userAgent.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    } else if (userAgent.indexOf("Android") !== -1) {
      operatingSystem = "Android OS";
    } else if (userAgent.indexOf("iOS") !== -1) {
      operatingSystem = "iOS";
    } else {
      operatingSystem = "Unknown";
    }

    setOperatingSystem(operatingSystem);
  }, []);

  // Find The Device Type Of The User
  useEffect(() => {
    // Function to update device type based on screen width
    const updateDeviceType = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setDeviceType("Mobile");
      } else if (width <= 1024) {
        setDeviceType("Tablet");
      } else {
        setDeviceType("Desktop");
      }
    };

    // Update device type when the component mounts
    updateDeviceType();

    // Add an event listener to update device type when the window is resized
    window.addEventListener("resize", updateDeviceType);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  const handleLanguageSelection = value => {
    if (value.length > 0) {
      setSelectedLanguage(value);
      setShowLanguageError("none");
    } else {
      setShowLanguageError("block");
    }
  };

  const onSubmit = async data => {
    try {
      setLoading(true);

      // leadSquared params
      let leadSquaredParams = {
        FirstName: data.name,
        Phone: data.phone,
        EmailAddress: data.email,
        mx_Website_CTA: "metabolism_assessment_quiz",
        mx_Latest_Source: props.utm_source || "",
        mx_quiz_weight: props.userQuizResult.weight,
        mx_quiz_hba1c: props.userQuizResult.sugarLevel,
        mx_Height: props.userQuizResult.height,
        mx_Language: selectedLanguage[0].display,
        mx_quiz_medicines: props.userQuizResult.medicationTypeOption,
        mx_Pack_Amount: props.userQuizResult.medicationOption,
        mx_How_often_you_smoke: props.userQuizResult.smokeOption,
        mx_How_often_you_consume_alcohol: props.userQuizResult.drinkOption,
        mx_capture_the_metabolic_score: props.finalQuizScore,
        mx_capture_BMI: props.bmiScore,
        mx_Device_Mobile_Desktop: deviceType,
        mx_msc_Operating_System: operatingSystem,
      };

      data.language = selectedLanguage;

      if (selectedLanguage.length > 0) props.handleUserDetails(data);

      // navigate to result page
      if (props.resultPageUrl) navigate(props.resultPageUrl);
      setLoading(false);

      // lsq event
      await sendToLSQ(leadSquaredParams);
      // clevertap event
      sendToCT(props.ctEvent, leadSquaredParams);
    } catch (error) {
      setLoading(true);
      console.error("Metabolism Assessment quiz lsq call error", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container twinSecContainer">
        <div className={`row ${styles.quizForm__row}`}>
          <div className={`col-12 ${styles.quizForm__hdngCol}`}>
            <h3>Your metabolic score is ready!</h3>
            <p>Fill in the details below to know your result</p>
          </div>
          <div className={styles.quizForm__form}>
            <form
              name="metabolismAssesment"
              onSubmit={handleSubmit(onSubmit)}
              className={`mt-3 row`}
            >
              <div className={`col-md-12`}>
                <div className={`form-group mb-4 ${styles.formInput}`}>
                  <label htmlFor="name">Full Name*</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Full Name*"
                    id="name"
                    type="text"
                    {...register("name", {
                      required: "Please enter your name",
                      pattern: {
                        value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid name",
                      },
                      maxLength: { value: 50, message: "Name is too long" },
                    })}
                  />
                  {errors.name && (
                    <span className="invalid-feedback">
                      {errors.name.message}
                    </span>
                  )}
                </div>
              </div>
              <div className={`col-md-12`}>
                <div className={`form-group mb-4 ${styles.formInput}`}>
                  <label htmlFor="phone">Whatsapp Number*</label>
                  <input
                    className={`form-control letterSp1 ${
                      errors.phone ? "is-invalid" : ""
                    }`}
                    placeholder="WhatsApp Number*"
                    id="phone"
                    type="text"
                    inputMode="numeric"
                    maxLength="10"
                    {...register("phone", {
                      required: "Please enter your whatsapp number",
                      pattern: {
                        value:
                          /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid phone number",
                      },
                      maxLength: {
                        value: 10,
                        message: "Invalid phone number",
                      },
                    })}
                  />
                  {errors.phone && (
                    <span className="invalid-feedback">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </div>

              <div className={`col-md-12`}>
                <div className={`form-group mb-4 ${styles.formInput}`}>
                  <label htmlFor="email">Email Id*</label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Email*"
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Please enter your email id",
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="invalid-feedback">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>
              <div className={`col-md-12`}>
                <div>
                  <p>Language*</p>
                </div>
                <div
                  className={`assessquiz__langModal__dropdown ${styles.langModal__dropdown}`}
                >
                  <DropDown
                    displayValue="display"
                    onSelect={handleLanguageSelection}
                    onRemove={handleLanguageSelection}
                    options={constants.QUIZ_LANGUAGES}
                    showCheckbox={true}
                    selectionLimit={1}
                    placeholder="Language"
                    hidePlaceholder={true}
                    showArrow={true}
                    customCloseIcon={
                      <StaticImage
                        src="../../../images/bookPack/cross_2.png"
                        alt="cross_icon"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{
                          width: "14px",
                          height: "14px",
                          marginLeft: "4px",
                        }}
                      />
                    }
                    customArrow={
                      <StaticImage
                        src="../../../images/bookPack/arrow_dropdown.png"
                        alt="cross_icon"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{
                          marginTop: "9px",
                        }}
                      />
                    }
                  />

                  {/* To show language error */}
                  {selectedLanguage.length === 0 && (
                    <span
                      className="text-danger"
                      style={{
                        fontSize: "0.875em",
                        display: showLanguageError,
                      }}
                    >
                      <p style={{ marginTop: "22px" }}>
                        Please select a language
                      </p>
                    </span>
                  )}
                </div>
              </div>
              <div className={`col-12 mt-2 cursorPointer`}>
                <button
                  type="submit"
                  className={`gradientBtnWithArrow w-100 pt-2 pb-2 ${styles.submitBtn}`}
                  onClick={() => setShowLanguageError("block")}
                >
                  <span className={loading && styles.quizForm__ctaLoader}>
                    Submit
                  </span>
                  <span className={styles.quizForm__loader}>
                    <Loader
                      type="TailSpin"
                      color="#FFF"
                      height={30}
                      width={30}
                      radius={1}
                      visible={loading}
                    />
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div className={`col-12 ${styles.quizForm__footer}`}>
            <p>
              *By clicking 'Submit' you agree to be contacted by Twin Health via
              Call, SMS, WhatsApp & Email.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizForm;
